import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 288.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,288.000000) scale(0.100000,-0.100000)">
   

   <path d="M1284 2410 c-13 -23 -50 -53 -105 -87 -122 -74 -187 -135 -200 -188
-6 -24 -24 -55 -40 -71 l-29 -28 11 33 c13 38 7 227 -9 243 -5 5 -13 -19 -19
-65 -7 -48 -27 -111 -57 -180 -31 -72 -46 -120 -46 -150 0 -24 -6 -51 -13 -61
-11 -15 -12 3 -9 108 3 75 1 126 -5 126 -10 0 -46 -103 -63 -180 -5 -25 -10
-99 -11 -165 -1 -105 -2 -113 -10 -67 -12 71 -86 208 -103 191 -3 -3 1 -68 9
-145 12 -118 19 -148 45 -198 20 -40 30 -73 30 -105 0 -74 29 -229 54 -295 13
-34 29 -90 36 -126 6 -35 20 -81 30 -100 24 -47 91 -107 148 -132 25 -11 72
-41 106 -65 78 -58 149 -94 256 -129 78 -26 97 -28 245 -28 166 -1 281 17 322
51 17 13 16 14 -7 9 -14 -3 -61 -14 -105 -26 -150 -38 -344 -27 -501 30 -57
22 -234 119 -234 130 0 3 39 5 88 6 103 0 157 20 226 84 70 64 96 127 95 224
-1 152 -40 209 -267 390 -93 74 -179 147 -191 162 -26 33 -29 120 -5 170 24
49 87 84 153 84 90 0 145 -39 160 -113 4 -17 10 -31 14 -29 4 1 7 4 7 7 0 3 0
40 0 83 l0 78 -97 12 c-132 15 -207 -2 -257 -57 -24 -26 -36 -27 -29 -1 8 31
-13 24 -36 -12 -11 -18 -39 -51 -62 -73 -23 -22 -54 -58 -70 -79 -16 -21 -29
-32 -29 -24 0 21 58 160 73 175 7 6 48 26 91 43 88 34 122 56 179 109 55 52
49 64 -19 40 -55 -18 -107 -44 -169 -84 -20 -13 -26 -15 -19 -5 47 64 79 99
97 105 12 4 87 10 167 14 134 7 150 10 197 36 55 29 90 61 80 71 -3 3 -16 -1
-29 -10 -16 -12 -46 -16 -108 -16 -47 -1 -116 -5 -155 -10 -38 -5 -71 -7 -73
-6 -6 6 134 84 195 109 118 48 189 61 328 61 142 0 233 -18 350 -69 143 -63
285 -186 375 -322 163 -250 184 -584 53 -846 -13 -27 -22 -52 -19 -55 9 -9 20
7 49 75 68 154 91 341 63 501 -31 179 -103 324 -225 454 -121 129 -241 204
-408 255 -77 23 -106 26 -228 27 -152 0 -236 -15 -334 -60 -30 -13 -55 -23
-57 -21 -2 2 18 19 45 40 33 24 58 54 76 91 32 66 32 111 -1 56z m-23 -67
c-16 -31 -73 -79 -150 -128 -40 -25 -84 -58 -97 -72 -30 -33 -31 -17 -1 28 13
19 73 68 137 111 63 43 116 77 118 78 1 0 -2 -8 -7 -17z m-345 -217 c-8 -60
-44 -137 -87 -186 l-31 -35 7 30 c4 17 25 70 47 120 22 49 42 105 45 123 10
57 26 9 19 -52z m389 -25 c-9 -16 -104 -30 -222 -33 -97 -3 -111 -1 -98 11 7
8 42 17 77 21 96 11 250 11 243 1z m-558 -188 c-3 -61 -13 -121 -26 -158 l-21
-60 5 89 c5 86 31 226 42 226 3 0 3 -44 0 -97z m262 54 c-40 -37 -195 -115
-206 -103 -11 11 218 147 245 146 4 -1 -14 -20 -39 -43z m-377 -204 c25 -53
33 -84 36 -148 2 -44 2 -90 0 -100 -8 -35 -47 65 -57 146 -18 144 -21 169 -15
169 3 0 19 -30 36 -67z m243 -7 c-17 -44 -175 -214 -175 -188 0 27 33 75 105
154 41 45 75 87 76 93 0 5 3 4 6 -2 2 -7 -3 -33 -12 -57z m-10 -163 c8 -48 43
-83 220 -224 88 -70 170 -140 182 -157 60 -81 72 -180 34 -268 -27 -63 -61
-96 -124 -124 -108 -48 -222 -35 -282 32 -65 71 -115 158 -115 198 0 50 25
130 55 174 29 42 30 46 13 46 -21 0 -59 -64 -95 -159 -20 -51 -72 191 -73 333
l0 69 88 86 c86 86 87 86 90 56 1 -16 5 -44 7 -62z m253 -175 c95 -77 186
-158 202 -180 36 -50 60 -126 59 -187 -1 -79 -15 -87 -15 -9 0 53 -6 86 -22
121 -25 55 -100 138 -181 199 -165 126 -252 207 -250 235 0 4 8 -3 17 -16 9
-13 94 -87 190 -163z"/>
<path d="M2110 1900 c-14 -4 -143 -8 -287 -9 -168 -1 -263 -5 -263 -11 0 -5
13 -10 29 -10 17 0 42 -10 58 -24 l28 -24 3 -421 c2 -368 1 -426 -13 -455 -16
-34 -50 -56 -85 -56 -11 0 -20 -4 -20 -8 0 -4 72 -8 160 -8 155 0 197 7 125
20 -19 4 -43 18 -55 33 -19 24 -20 39 -20 224 l0 199 243 0 c133 0 247 -4 253
-8 17 -11 34 -48 34 -73 0 -12 3 -19 7 -15 9 9 11 236 2 236 -4 0 -9 -13 -12
-29 -12 -60 -17 -61 -260 -61 -122 0 -232 -3 -244 -6 l-23 -6 0 226 0 226 168
0 c151 0 171 -2 185 -18 10 -10 17 -31 17 -45 0 -15 5 -27 10 -27 6 0 10 33
10 80 0 85 -1 86 -50 70z m-371 -542 c-14 -27 -19 -22 -19 19 0 34 1 35 14 18
10 -14 11 -25 5 -37z"/>
<path d="M2256 953 c-3 -4 -6 -15 -6 -25 0 -24 33 -21 38 3 3 17 -21 33 -32
22z"/>
<path d="M2153 824 c-9 -23 13 -40 32 -24 12 10 13 16 4 26 -15 18 -28 18 -36
-2z"/>
<path d="M2041 726 c-9 -10 -8 -16 4 -26 19 -16 41 1 32 24 -8 20 -21 20 -36
2z"/>
<path d="M1911 646 c-9 -10 -8 -16 4 -26 19 -16 41 1 32 24 -8 20 -21 20 -36
2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
